<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          dense
          label="Description"
          v-model="subscription.description"
          :counter="50"
          :rules="commonRules"
          required
          hint="example of helper text only on focus"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          label="Expires"
          v-model="subscription.expires"
          :counter="50"
          :rules="iso8601Rules"
          required
          hint="example of helper text only on focus"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          label="Throttling"
          v-model.number="subscription.throttling"
          :counter="50"
          :rules="numberRules"
          required
          hint="example of helper text only on focus"
        ></v-text-field>
      </v-col>
      <v-col
        class="mb-n5"
        cols="12"
      >
        <p class="text-button">Subject *</p>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card
          outlined
        >
          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Entities *</div>
              </v-col>
              <v-col
                class="mt-n4"
                cols="12"
                sm="10"
              >
                <v-row
                  v-for="(entities, entitiesIndex) in subscription.subject.entities"
                  :key="entitiesIndex"
                >
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      dense
                      label="ID Pattern*"
                      :counter="50"
                      :rules="entitiesIndex + 1 == subscription.subject.entities.length && entitiesIndex > 0 ? [] : commonRules"
                      required
                      hint="example of helper text only on focus"
                      v-model="entities.idPattern"
                      @input="addSubjectEntites(entitiesIndex)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      dense
                      label="Type*"
                      :counter="50"
                      :rules="entitiesIndex + 1 == subscription.subject.entities.length && entitiesIndex > 0 ? [] : commonRules"
                      required
                      hint="example of helper text only on focus"
                      v-model="entities.type"
                      @input="addSubjectEntites(entitiesIndex)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Condition</div>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-combobox
                  class="mt-n4"
                  v-model="subscription.subject.condition.attrs"
                  :search-input.sync="subscription.subject.condition.inputValue"
                  hide-selected
                  label="Add attribute names"
                  multiple
                  persistent-hint
                  small-chips
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Press <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
              <template v-if="subscription.subject.condition.attrs.length > 0">
                <v-col
                  cols="12"
                  sm="2"
                >
                  <div class="subtitle-1 text--secondary">Expression</div>
                </v-col>
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-row
                    class="ml-2 mt-n4"
                    v-for="(exp, expIndex) in subscription.subject.condition.expression"
                    :key="expIndex"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        label="key"
                        :counter="50"
                        :rules="expIndex + 1 == subscription.subject.condition.expression.length && expIndex > 0 ? [] : commonRules"
                        required
                        hint="example of helper text only on focus"
                        v-model="exp.key"
                        @input="addSubjectConditionExpression(expIndex)"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        label="value"
                        :counter="50"
                        :rules="expIndex + 1 == subscription.subject.condition.expression.length && expIndex > 0 ? [] : commonRules"
                        required
                        hint="example of helper text only on focus"
                        v-model="exp.value"
                        @input="addSubjectConditionExpression(expIndex)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        cols="12"
      >
        <p class="text-button">Notification *</p>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card
          outlined
        >
          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Protocol</div>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-btn-toggle
                  mandatory
                  v-model="subscription.notification.protocol"
                  rounded
                >
                  <v-btn
                    x-small
                    value="http"
                  >
                    Http
                  </v-btn>
                  <v-btn
                    x-small
                    value="httpCustom"
                  >
                    Http-Custom
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Url *</div>
              </v-col>
              <v-col
                class="mt-n1"
                cols="12"
                sm="10"
              >
                <v-text-field
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="subscription.notification.http.url"
                ></v-text-field>
              </v-col>
              <template v-if="subscription.notification && subscription.notification.protocol === 'httpCustom'">
                <v-col
                  cols="12"
                  sm="2"
                >
                  <div class="subtitle-1 text--secondary">Header</div>
                </v-col>
                <v-col
                  class="mt-n4"
                  cols="12"
                  sm="10"
                >
                  <v-row
                    v-for="(header, headerIndex) in subscription.notification.http.headers"
                    :key="headerIndex"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        label="key"
                        :counter="50"
                        :rules="headerIndex + 1 == subscription.notification.http.headers.length && headerIndex > 0 ? [] : commonRules"
                        required
                        hint="example of helper text only on focus"
                        v-model="header.key"
                        @input="addNotificationHTTPheader(headerIndex)"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        label="value"
                        :counter="50"
                        :rules="headerIndex + 1 == subscription.notification.http.headers.length && headerIndex > 0 ? [] : commonRules"
                        required
                        hint="example of helper text only on focus"
                        v-model="header.value"
                        @input="addNotificationHTTPheader(headerIndex)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                >
                  <div class="subtitle-1 text--secondary">Qs</div>
                </v-col>
                <v-col
                  class="mt-n4"
                  cols="12"
                  sm="10"
                >
                  <v-row
                    v-for="(qs, qsIndex) in subscription.notification.http.qs"
                    :key="qsIndex"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        label="key"
                        :counter="50"
                        :rules="qsIndex + 1 == subscription.notification.http.qs.length && qsIndex > 0 ? [] : commonRules"
                        required
                        hint="example of helper text only on focus"
                        v-model="qs.key"
                        @input="addNotificationHTTPqs(qsIndex)"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        label="value"
                        :counter="50"
                        :rules="qsIndex + 1 == subscription.notification.http.qs.length && qsIndex > 0 ? [] : commonRules"
                        required
                        hint="example of helper text only on focus"
                        v-model="qs.value"
                        @input="addNotificationHTTPqs(qsIndex)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Attribute Format</div>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-btn-toggle
                  v-model="subscription.notification.attrsFormat"
                  rounded
                >
                  <v-btn
                    x-small
                    value="values"
                  >
                    Values
                  </v-btn>
                  <v-btn
                    x-small
                    value="keyValues"
                  >
                    Key-Values
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
              class="mt-4"
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Attribute</div>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-combobox
                  v-model="subscription.notification.attrs.values"
                  :search-input.sync="subscription.notification.attrs.inputValue"
                  hide-selected
                  label="Add attribute names"
                  multiple
                  persistent-hint
                  small-chips
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Press <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Time Sent</div>
              </v-col>
              <v-col
                class="mt-n1"
                cols="12"
                sm="10"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="subscription.notification.timesSent"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Last Notification</div>
              </v-col>
              <v-col
                class="mt-n1"
                cols="12"
                sm="10"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="subscription.notification.lastNoti"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Last Failure</div>
              </v-col>
              <v-col
                class="mt-n1"
                cols="12"
                sm="10"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="subscription.notification.lastFail"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Last Success</div>
              </v-col>
              <v-col
                class="mt-n1"
                cols="12"
                sm="10"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="subscription.notification.lastSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <small>*indicates required field</small>
    <snack-bar v-model="snackbar.active" :text="snackbar.text"></snack-bar>
  </v-form>
</template>

<script>
import api from '@/services/api'

var subscriptionForm = {
  description: null,
  subject: {
    entities: [{
      idPattern: null,
      type: null
    }],
    condition: {
      attrs: [],
      inputValue: null,
      expression: [
        {
          key: null,
          value: null
        }
      ]
    }
  },
  notification: {
    protocol: 'http',
    http: {
      url: null,
      headers: [
        {
          key: null,
          value: null
        }
      ],
      qs: [
        {
          key: null,
          value: null
        }
      ],
      method: null,
      payload: null
    },
    attrsFormat: null,
    attrs: {
      values: [],
      inputValue: null
    },
    timesSent: '',
    lastNoti: '',
    lastFail: '',
    lastSuccess: ''
  },
  expires: null,
  throttling: null,
  status: null
}

Object.freeze(subscriptionForm)

export default {
  name: 'FiwareSubscriptionForm',
  components: {
    SnackBar: () => import('@/components/SnackBar')
  },
  props: {
    subscription: {
      type: Object,
      default () {
        return subscriptionForm
      },
      required: true
    },
    id: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    snackbar: {
      active: false,
      text: null
    }
  }),
  methods: {
    createSubscription: function () {
      api.post('/v2/subscriptions/', this.filterSubscriptionForm, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 201) {
          console.log('Created :)')
          this.$emit('action', false)
        }
      })
    },
    updateSubscription: function () {
      api.patch(`/v2/subscriptions/${this.id}`, this.filterSubscriptionForm, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 204) {
          console.log('Updated :)')
          this.$emit('action', false)
          this.snackbar.active = true
          this.snackbar.text = 'Updated'
        }
      })
    },
    addSubjectEntites: function (subjectEntitiesIndex) {
      if (this.subscription.subject.entities.length === subjectEntitiesIndex + 1) this.subscription.subject.entities.push({ idPattern: null, type: null })
      if (!this.subscription.subject.entities[subjectEntitiesIndex].idPattern && !this.subscription.subject.entities[subjectEntitiesIndex].type) {
        if (this.subscription.subject.entities.length > 0) {
          this.subscription.subject.entities = this.subscription.subject.entities.filter((value, index) => index !== subjectEntitiesIndex)
        }
      }
    },
    addSubjectConditionExpression: function (expIndex) {
      if (this.subscription.subject.condition.expression.length === expIndex + 1) {
        this.subscription.subject.condition.expression.push({ key: null, value: null })
      }
      if (!this.subscription.subject.condition.expression[expIndex].key && !this.subscription.subject.condition.expression[expIndex].value) {
        this.subscription.subject.condition.expression = this.subscription.subject.condition.expression.filter((value, index) => index !== expIndex)
      }
    },
    addNotificationHTTPheader: function (headerIndex) {
      if (this.subscription.notification.http.headers.length === headerIndex + 1) {
        this.subscription.notification.http.headers.push({ key: null, value: null })
      }
      if (!this.subscription.notification.http.headers[headerIndex].key && !this.subscription.notification.http.headers[headerIndex].value) {
        this.subscription.notification.http.headers = this.subscription.notification.http.headers.filter((value, index) => index !== headerIndex)
      }
    },
    addNotificationHTTPqs: function (qsIndex) {
      if (this.subscription.notification.http.qs.length === qsIndex + 1) {
        this.subscription.notification.http.qs.push({ key: null, value: null })
      }
      if (!this.subscription.notification.http.qs[qsIndex].key && !this.subscription.notification.http.qs[qsIndex].value) {
        this.subscription.notification.http.qs = this.subscription.notification.http.qs.filter((value, index) => index !== qsIndex)
      }
    },
    resetValidateForm: function () {
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    filterSubscriptionForm: {
      get: function () {
        const subscription = {
          expires: this.subscription.expires,
          subject: {
            entities: []
          },
          notification: {}
        }
        if (this.subscription.description) {
          subscription.description = this.subscription.description
        }
        if (this.subscription.throttling) {
          subscription.throttling = this.subscription.throttling
        }
        this.subscription.subject.entities.forEach(entitie => {
          if (entitie.idPattern && entitie.type) {
            subscription.subject.entities.push(entitie)
          }
        })
        let condition = {}
        let expreesion = {}
        if (this.subscription.subject.condition.attrs.length > 0) {
          Object.assign(condition, { attrs: this.subscription.subject.condition.attrs })
          this.subscription.subject.condition.expression.forEach(exp => {
            if (exp.key && exp.value) {
              Object.assign(expreesion, { [exp.key]: exp.value })
            }
          })
          subscription.subject.condition = Object.assign(condition, { expression: expreesion })
        }
        if (this.subscription.notification.protocol === 'http') {
          subscription.notification.http = {
            url: this.subscription.notification.http.url
          }
        } else if (this.subscription.notification.protocol === 'httpCustom') {
          let httpCustom = {}
          let headerObj = {}
          let qsObj = {}
          Object.assign(httpCustom, { url: this.subscription.notification.http.url })
          this.subscription.notification.http.headers.filter(header => header.key && header.value).forEach(header => {
            Object.assign(headerObj, { [header.key]: header.value })
          })
          this.subscription.notification.http.qs.filter(qsf => qsf.key && qsf.value).forEach(qsf => {
            Object.assign(qsObj, { [qsf.key]: qsf.value })
          })
          subscription.notification.httpCustom = Object.assign(httpCustom, { headers: headerObj }, { qs: qsObj })
        }
        if (this.subscription.notification.attrsFormat) {
          subscription.notification.attrsFormat = this.subscription.notification.attrsFormat
        }
        if (this.subscription.notification.attrs.values.length > 0) {
          subscription.notification.attrs = this.subscription.notification.attrs.values
        }
        if (this.subscription.notification.timesSent) {
          subscription.notification.timesSent = this.subscription.notification.timesSent
        }
        if (this.subscription.notification.lastNoti) {
          subscription.notification.lastNotification = this.subscription.notification.lastNoti
        }
        if (this.subscription.notification.lastFail) {
          subscription.notification.lastFailure = this.subscription.notification.lastFail
        }
        if (this.subscription.notification.lastSuccess) {
          subscription.notification.lastSuccess = this.subscription.notification.lastSuccess
        }
        return subscription
      }
    },
    testnaja: function () {
      return this.subscription
    },
    commonRules: function () {
      return [
        v => !!v || 'field is required',
        v => (v && v.length <= 50) || 'field must be less than 50 characters'
      ]
    },
    iso8601Rules: function () {
      return [
        v => !!v || 'field is required',
        v => new Date(v).toJSON() === v || 'field not match ISO8601 format'
      ]
    },
    numberRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+\.\d+)$|^(-?\d+)$/i.test(v) || 'field must be number'
      ]
    }
  },
  created () {
  }
}
</script>
